//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' ClieXito Corp.';
};

$(function(){
var ua = navigator.userAgent;
var window_width = $(window).width();
if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0 && 670 <=window_width && window_width<=1100){
$('head meta[name="viewport"]').attr('content','width=1100');
}
});

// ヘッダー、スクロールでサイズ縮小（.-topMenuを追加）
$(function(){
	 $(window).scroll(function(){
			var scr = $(window).scrollTop();
			if(scr >= 200){
				 $("#header").addClass('header--short');
			}else{
				 $("#header").removeClass('header--short');
			}
	 })
})

// 高さ揃え
$(function(){
　　$('.topAboutFigure__textEn').heightLine();
});

// ハンバーガーメニュー
$(function(){
	$(".js_openBtn").on("click", function() {
		$(this).next().slideToggle();
		$(this).toggleClass("spMenuBtn--open");
	});
	$(".gnav__link").on("click", function() {
		if (window.matchMedia( "(max-width: 768px)" ).matches) {
			$(".gnav__list").slideToggle();
			$(".js_openBtn").toggleClass("spMenuBtn--open");
		}
	});
});

// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});

new WOW().init();

